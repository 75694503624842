import { Component } from "react";
import PropTypes from "prop-types";

import ReactTable from "react-table";
import Loading from "../../common/Loading";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { DropdownButton, MenuItem } from "react-bootstrap";

import moment from "moment";

class InvoiceCallList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { loading, calls, selected } = this.props;

    const allSelected = calls.every((call) =>
      selected.some((c) => c._id === call._id)
    );

    const columns = [
      {
        accessor: "_id",
        Header: () => (
          <input
            type="checkbox"
            style={{ margin: "3px auto" }}
            onChange={this.props.toggleSelectAll}
            checked={allSelected}
            defaultChecked={allSelected}
          />
        ),
        Cell: (row) =>
          (
            <input
              type="checkbox"
              onChange={() => this.props.onToggle(row.original)}
              defaultChecked={selected.some(
                (call) => call._id === row.original._id
              )}
              checked={selected.some((call) => call._id === row.original._id)}
            />
          ) || null,
        sortable: false,
        maxWidth: 60,
        style: {
          textAlign: "center",
        },
      },
      {
        Header: "Contract",
        accessor: "contract.name",
      },
      {
        Header: "Staff",
        accessor: "assigned_to.full_name",
        Cell: (row) => row.value || null,
      },
      {
        Header: "Date of Service",
        accessor: "start_time",
        Cell: (row) =>
          <span>{moment(row.value).format("MM/DD/YYYY")}</span> || null,
      },
      {
        Header: "Product Category",
        accessor: "product_category",
        Cell: (row) =>
          <span>{row.value ? row.value.label : "--"}</span> || null,
      },
      {
        Header: "Billing Quantity",
        accessor: "billing_quantity",
        id: "billing_quantity",
        Cell: (row) => row.value.toFixed(2) || null,
      },
      {
        Header: "Total",
        accessor: "billing_total",
        id: "billing_total",
        Cell: (row) =>
          typeof row.value === "number" ? `$${row.value.toFixed(2)}` : null,
      },
      {
        Header: "",
        accessor: "_id",
        sortable: false,
        Cell: (row) =>
          (
            <div className="text-center actions">
              <DropdownButton
                title="Actions"
                id="dropdown"
                onClick={(e) => e.stopPropagation()}
              >
                <LinkContainer to={`/app/calls/${row.original._id}`}>
                  <MenuItem>
                    <FontAwesomeIcon icon={faFolder} />
                    View
                  </MenuItem>
                </LinkContainer>
              </DropdownButton>
            </div>
          ) || null,
      },
    ];

    const props = {
      getTrGroupProps: (state, row) => {
        if (state && row && row.original) {
          const isSelected = selected.some(
            (call) => call._id === row.original._id
          );
          return {
            style: {
              color: isSelected ? "#cf4a03" : "inherit",
              fontWeight: isSelected ? 500 : 400,
            },
          };
        } else {
          return { style: {} };
        }
      },
      getTdProps: (_state, rowInfo, column) => {
        return {
          onClick: () => {
            if (column.id !== "_id") {
              this.props.onToggle(rowInfo.original);
            }
          },
        };
      },
    };

    return (
      <ReactTable
        {...props}
        manual
        className="-highlight"
        data={calls}
        LoadingComponent={() => <Loading active={loading} />}
        columns={columns}
        pageSize={Math.min(calls.length, this.props.pageSize)}
        pages={Math.ceil(this.props.count / this.props.pageSize)}
        page={this.props.page}
        sorted={this.props.sorted}
        onPageChange={this.props.onPageChange}
        onPageSizeChange={this.props.onPageSizeChange}
        onSortedChange={this.props.onSortedChange}
        defaultPageSize={this.props.pageSize}
        defaultSorted={this.props.sorted}
      />
    );
  }
}

InvoiceCallList.propTypes = {
  calls: PropTypes.array.isRequired,
  onToggle: PropTypes.func,
  selected: PropTypes.array.isRequired,
  loading: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  pageSize: PropTypes.number,
  page: PropTypes.number,
  count: PropTypes.number,
  sorted: PropTypes.array,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onSortedChange: PropTypes.func,
  toggleSelectAll: PropTypes.func,
};

export default InvoiceCallList;
