import { find, isEmpty } from "lodash";
import moment from "moment";

export default function checkProceduresDate(contract, entries, startTime) {
  // Handle invalid procedures based on end date
  let isBetween = true;
  if (!isEmpty(contract) && startTime) {
    const format = (date) => moment(date).startOf("day").format("YYYY-MM-DD");

    entries.map((entry) => {
      const procedure = find(contract.lines, { _id: entry.procedure });
      if (procedure) {
        const callStart = format(startTime);
        const lineStart = format(procedure.start_date);
        const lineEnd = moment(procedure.end_date)
          .endOf("day")
          .format("YYYY-MM-DD");

        // [] indicates inclusion of value
        if (!moment(callStart).isBetween(lineStart, lineEnd, null, [])) {
          isBetween = false;
        }
      }
    });
  }

  return isBetween;
}
