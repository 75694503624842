import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Formsy from "formsy-react";
import ChangePassForm from "./ChangePassForm";
import { Input } from "formsy-react-components";
import Modal from "../common/Modal";
import { Row, Col } from "react-bootstrap";

import * as modalActions from "../../actions/modalActions";

class ProfileForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      canSubmit: false,
    };
  }

  enableButton = () => {
    this.setState({ canSubmit: true });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  cancel = () => {
    this.props.onCancel();
  };

  updatePassword = (model) => {
    this.props.handleChangePassword(model);
    this.props.actions.hideModal();
  };

  submit = (model) => {
    this.props.onSave(model);
  };

  render() {
    let { user } = this.props;
    let changePasswordForm = (
      <ChangePassForm
        user={user}
        onSave={this.updatePassword}
        onCancel={this.props.actions.hideModal}
      />
    );

    return (
      <Formsy
        ref="form"
        className="vertical form"
        onValidSubmit={this.submit}
        autoComplete="off"
        onValid={this.enableButton}
        onInvalid={this.disableButton}
      >
        <Row>
          <Col md={5} xs={12}>
            <Input
              formNoValidate
              required
              name="first_name"
              label="First Name"
              disabled={!this.props.editing}
              placeholder="First Name"
              value={user.first_name}
            />
          </Col>
          <Col md={2} xs={12}>
            <Input
              formNoValidate
              name="middle_initial"
              label="Middle Initial"
              disabled={!this.props.editing}
              placeholder="Middle Initial"
              value={user.middle_initial}
            />
          </Col>
          <Col md={5} xs={12}>
            <Input
              formNoValidate
              required
              name="last_name"
              label="Last Name"
              disabled={!this.props.editing}
              placeholder="Last Name"
              value={user.last_name}
            />
          </Col>
          <Col md={12} xs={12}>
            <Input
              formNoValidate
              required
              name="email"
              label="Email"
              placeholder="Email"
              value={user.email}
              disabled={!this.props.editing}
              validations="isEmail"
              validationError="This is not a valid email"
            />
          </Col>
          <Col md={12} xs={12}>
            <span
              className="pull-right form-action"
              onClick={this.props.onChangePassword}
            >
              Change Password
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12} className="action-footer">
            <button
              className="btn btn-danger"
              type="button"
              onClick={this.cancel}
            >
              Cancel
            </button>
            <input
              className="btn btn-success"
              type="submit"
              disabled={!this.state.canSubmit}
              value={this.props.saving ? "Saving... " : "Save"}
            />
          </Col>
        </Row>
        <Modal
          id="userDetailsModal"
          title="Change Password"
          body={changePasswordForm}
          modal={this.props.modal}
          close={this.props.actions.hideModal}
        />
      </Formsy>
    );
  }
}

ProfileForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  codes: PropTypes.array.isRequired,
  showPass: PropTypes.bool,
  divisions: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  glaccounts: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
};

function mapStatesToProps(state, ownProps) {
  return {
    modal: state.reducers.modal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
      },
      dispatch
    ),
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ProfileForm);
