import { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as invoiceActions from "../../actions/invoiceActions";

import Loading from "../common/Loading";
import ReactTable from "react-table";
import { Button } from "react-bootstrap";

import moment from "moment";

class InvoiceApprovalList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { invoices, selected, loading, count } = this.props;

    const allSelected = invoices.every((invoice) =>
      selected.some((i) => i._id === invoice._id)
    );

    const columns = [
      {
        accessor: "_id",
        Header: () => (
          <input
            type="checkbox"
            style={{ margin: "3px auto" }}
            onChange={this.props.toggleSelectAll}
            checked={allSelected}
            defaultChecked={allSelected}
          />
        ),
        Cell: (row) => (
          <input
            type="checkbox"
            onChange={() => this.props.onToggle(row.original)}
            defaultChecked={selected.some(
              (invoice) => invoice.number === row.original.number
            )}
            checked={selected.some(
              (invoice) => invoice.number === row.original.number
            )}
          />
        ),
        sortable: false,
        filterable: false,
        maxWidth: 60,
        style: {
          textAlign: "center",
        },
      },
      {
        Header: "Number",
        id: "number",
        maxWidth: 120,
        accessor: (d) => d.number,
      },
      {
        Header: "Account",
        accessor: "parent_account.name",
      },
      {
        Header: "Contract",
        accessor: "contract.name",
      },
      {
        Header: "Date",
        accessor: "document_date",
        Cell: (row) => (
          <span>{moment.utc(row.value).format("MM/DD/YYYY")}</span>
        ),
      },
      {
        Header: "Division",
        id: "division",
        accessor: (d) => d.division.description,
      },
      {
        Header: "Format",
        accessor: "format.label",
      },
      {
        Header: "Action",
        accessor: "_id",
        sortable: false,
        Cell: (row) => (
          <div className="text-center actions">
            <Button
              title="Preview"
              onClick={() => this.props.onClickPreview(row.original)}
            >
              Preview
            </Button>
          </div>
        ),
      },
    ];

    const checkboxProps = {
      getTrGroupProps: (state, row) => {
        if (state && row && row.original) {
          const isSelected = selected.some(
            (inv) => inv._id === row.original._id
          );
          return {
            style: {
              color: isSelected ? "#cf4a03" : "inherit",
              fontWeight: isSelected ? 500 : 400,
            },
          };
        } else {
          return { style: {} };
        }
      },
      getTdProps: (_state, rowInfo, column) => {
        return {
          onClick: () => {
            if (rowInfo) {
              if (column.id !== "_id") {
                this.props.onToggle(rowInfo.original);
              }
            }
          },
        };
      },
    };

    return (
      <ReactTable
        manual
        pageSize={Math.min(invoices.length, this.props.filters.pageSize)}
        pages={Math.ceil(count / this.props.filters.pageSize)}
        page={this.props.filters.page}
        sorted={this.props.filters.sorted}
        onPageChange={this.props.onPageChange}
        onPageSizeChange={this.props.onPageSizeChange}
        onSortedChange={this.props.onSortedChange}
        className={"-striped -highlight"}
        LoadingComponent={() => <Loading active={loading} />}
        data={invoices || []}
        columns={columns}
        showPagination={true}
        {...checkboxProps}
        defaultPageSize={this.props.filters.pageSize}
        defaultSorted={this.props.filters.sorted}
      />
    );
  }
}

InvoiceApprovalList.propTypes = {
  invoices: PropTypes.array,
  loading: PropTypes.bool,
  count: PropTypes.number,
  selected: PropTypes.array,
  onToggle: PropTypes.func,
  onClickPreview: PropTypes.func,
  filters: PropTypes.object,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  onSortedChange: PropTypes.func,
  toggleSelectAll: PropTypes.func,
};

function mapStatesToProps(state, ownProps) {
  return {
    invoicesToPrint: state.reducers.invoicesToPrint,
    loadingInvoice: state.reducers.loadingInvoice,
    invoicesToApprove: state.reducers.invoicesToApprove,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...invoiceActions,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(InvoiceApprovalList);
