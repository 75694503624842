import { Component } from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import InvoiceService from "../../services/invoiceService";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import autoBind from "react-autobind";

import * as modalActions from "../../actions/modalActions";
import * as userActions from "../../actions/userActions";
import * as accountActions from "../../actions/accountActions";
import * as chargetypeActions from "../../actions/chargetypeActions";
import * as contractActions from "../../actions/contractActions";
import * as divisionActions from "../../actions/divisionActions";
import * as invoiceActions from "../../actions/invoiceActions";
import * as itemActions from "../../actions/itemActions";

import InvoiceApprovalList from "./InvoiceApprovalList";
import InvoicePreview from "./invoiceLines/invoicePreview/InvoicePreview";
import BreadCrumbs from "../common/BreadCrumbs";
import Modal from "../common/Modal";
import Formsy from "formsy-react";
import Select from "react-select";

import { Row, Col, Collapse, Well } from "react-bootstrap";
import { DateRangePicker } from "react-dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import moment from "moment";

class InvoiceApprovalPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      open: false,
      list: true,
      preview: true,
      focusedInput: null,
      filters: localStorage.getItem("invoiceApprovalFilters")
        ? JSON.parse(localStorage.getItem("invoiceApprovalFilters"))
        : {
            parent_account: [],
            contract: [],
            number: [],
            division: [],
            start_time: null,
            end_time: null,
            page: 0,
            pageSize: 10,
            sorted: [
              {
                id: "number",
                desc: false,
              },
            ],
          },
      selected: [],
      invoices: [],
      count: 0,
      loading: true,
      invoice: null,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { accounts, items, chargetypes, divisions, actions, codes } =
      this.props;

    actions.loadDropdownContracts();

    if (codes?.length) {
      this.handleLoadInvoices(this.handlePrepFilters(codes));
    }

    actions.loadUsers();
    if (isEmpty(accounts)) actions.loadAccounts();
    if (isEmpty(chargetypes)) actions.loadChargetypes();
    if (isEmpty(divisions)) actions.loadDivisions();
    if (isEmpty(items)) actions.loadItems();
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.codes !== this.props.codes && nextProps.codes?.length) {
      this.handleLoadInvoices(this.handlePrepFilters());
    }
  };

  handleLoadInvoices = async () => {
    try {
      if (!this.props.codes?.length) return;
      this.setState({ loading: true });

      const query = this.handlePrepFilters();

      const { result, count } = await InvoiceService.loadInvoices(
        queryString.stringify(query, {
          arrayFormat: "bracket",
        })
      );

      this.setState({
        invoices: result,
        count,
      });
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ loading: false });
    }
  };
  handlePrepFilters = () => {
    const stage = this.props.codes?.find(
      (code) =>
        code.entity === "Invoice" &&
        code.field === "stage" &&
        code.label === "Created"
    );

    const transformedObject = {
      page: this.state.filters.page,
      pageSize: this.state.filters.pageSize,
      stage: stage ? [stage._id] : null,
    };
    const {
      parent_account,
      contract,
      number,
      division,
      start_time,
      end_time,
      sorted,
    } = this.state.filters;
    if (parent_account?.length) {
      transformedObject.parent_account = parent_account.map((x) => x.label);
    }
    if (contract?.length) {
      transformedObject.contract = contract.map((x) => x.label);
    }
    if (division?.length) {
      transformedObject.division = division.map((x) => x.value);
    }
    if (number?.length) {
      transformedObject.number = number.map((x) => x.label);
    }
    if (start_time) {
      transformedObject.start_time = start_time;
    }
    if (end_time) {
      transformedObject.end_time = end_time;
    }
    if (sorted?.length) {
      transformedObject.sort = sorted.map(
        (x) => `${[x.id]}: ${x.desc ? 1 : -1}`
      );
    }
    return transformedObject;
  };

  handleChangeFilters(selectedOptions, field) {
    this.setState(
      {
        filters: { ...this.state.filters, [field]: selectedOptions },
      },
      () => {
        this.handleLoadInvoices(this.handlePrepFilters());
        localStorage.setItem(
          "invoiceApprovalFilters",
          JSON.stringify(this.state.filters)
        );
      }
    );
  }

  handleChangeDate = (startDate, endDate) => {
    let { filters } = this.state;
    if (startDate) {
      filters.start_time = moment(startDate).endOf("day");
      this.setState({ filters: { ...filters } }, () => {
        this.handleLoadInvoices(this.handlePrepFilters());
        localStorage.setItem(
          "invoiceApprovalFilters",
          JSON.stringify(this.state.filters)
        );
      });
    }
    if (endDate) {
      filters.end_time = moment(endDate).endOf("day");
      this.setState({ filters: { ...filters } }, () => {
        this.handleLoadInvoices(this.handlePrepFilters());
        localStorage.setItem(
          "invoiceApprovalFilters",
          JSON.stringify(this.state.filters)
        );
      });
    }
  };

  resetFilters() {
    this.setState(
      {
        filters: {
          parent_account: [],
          contract: [],
          number: [],
          division: [],
          start_time: null,
          end_time: null,
          page: 0,
          pageSize: 10,
          sorted: [
            {
              id: "number",
              desc: false,
            },
          ],
        },
        selected: [],
        invoice: null,
      },
      () => {
        this.handleLoadInvoices(this.handlePrepFilters());
      }
    );
    localStorage.removeItem("invoiceApprovalFilters");
  }

  onClickDelete(invoiceId) {
    this.props.actions.requestInvoiceId(invoiceId);
  }

  onClickPreview(invoice) {
    this.setState({ invoice }, () => {
      this.props.actions.showModalSuccess("invoiceDetailsModal");
    });
  }

  handleToggle = (invoice) => {
    const { selected } = this.state;

    const nextSelected = [...selected];

    const idx = nextSelected.findIndex((s) => s._id === invoice._id);
    if (idx > -1) {
      nextSelected.splice(idx, 1);
    } else {
      nextSelected.push(invoice);
    }

    this.setState({ selected: nextSelected });
  };

  toggleSelectAll = () => {
    const { invoices, selected } = this.state;

    const someSelected = invoices.some((invoice) =>
      selected.some((i) => i._id === invoice._id)
    );

    let nextSelected = [...selected];

    if (someSelected) {
      // if some are selected unselect them all;
      nextSelected = nextSelected.filter((invoice) =>
        invoices.every((i) => i._id !== invoice._id)
      );
    } else {
      for (const invoice of invoices) {
        nextSelected.push(invoice);
      }
    }

    this.setState({ selected: nextSelected });
  };

  async bulkApprove() {
    const idArray = this.state.selected.map((invoice) => invoice._id);
    const user = this.props.auth.user;
    const stage = this.props.codes.filter(
      (code) => code.entity == "Invoice" && code.label === "Approved"
    );
    await this.props.actions.bulkApprove(idArray, user.id, stage[0].id);

    this.setState(
      {
        selected: [],
      },
      () => {
        this.handleLoadInvoices(this.handlePrepFilters());
      }
    );
  }

  genButtonText() {
    const { selected, count } = this.state;

    if (!selected.length) return "";
    return ` ${selected.length} of ${count} Invoices`;
  }

  handlePageSizeChange = (pageSize) => {
    this.setState({ filters: { ...this.state.filters, pageSize } }, () => {
      // this.props.actions.loadInvoices(this.handlePrepFilters());
      this.handleLoadInvoices(this.handlePrepFilters());
    });
  };

  handlePageChange = (page) => {
    this.setState({ filters: { ...this.state.filters, page } }, () => {
      // this.props.actions.loadInvoices(this.handlePrepFilters());
      this.handleLoadInvoices(this.handlePrepFilters());
    });
  };

  handleSortedChange = (newSort) => {
    this.setState(
      { filters: { ...this.state.filters, sorted: newSort } },
      () => {
        // this.props.actions.loadInvoices(this.handlePrepFilters());
        this.handleLoadInvoices(this.handlePrepFilters());
      }
    );
  };

  render() {
    const { invoices } = this.state;

    const invoiceNumberOptions = invoices.map((invoice) => ({
      value: invoice._id,
      label: invoice.number,
    }));

    let invoiceDetails = this.state.invoice && (
      <InvoicePreview
        invoices={[this.state.invoice]}
        codes={this.props.codes}
        users={this.props.users}
      />
    );

    return (
      <div className="content-wrapper">
        <Row>
          <BreadCrumbs breadcrumbs={[{ label: "Invoice Approval" }]} />
          <Col md={5} className="text-right">
            <button
              className="btn btn-success btn-sm call"
              type="button"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <FontAwesomeIcon icon={faFilter} className="icon" />
            </button>
            <button
              className="btn btn-success btn-sm btn-add"
              type="button"
              disabled={this.state.selected.length <= 0}
              onClick={this.bulkApprove}
            >
              {`APPROVE${this.genButtonText()}`}
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="settings-add">
            <Collapse in={this.state.open}>
              <div>
                <Well bsSize="large">
                  <Formsy ref="form" className="vertical form">
                    <Row>
                      <Col md={3} xs={12}>
                        <Select
                          isMulti
                          label="Number"
                          placeholder="Number"
                          value={this.state.filters.number}
                          onChange={(newVal) =>
                            this.handleChangeFilters(newVal, "number")
                          }
                          options={orderBy(invoiceNumberOptions, "label")}
                        />
                      </Col>
                      <Col md={3} xs={12}>
                        <Select
                          isMulti
                          label="Parent Account"
                          placeholder="Parent Account"
                          value={this.state.filters.parent_account}
                          onChange={(newVal) =>
                            this.handleChangeFilters(newVal, "parent_account")
                          }
                          options={orderBy(
                            [
                              ...this.props.accounts.map((account) => ({
                                value: account._id,
                                label: account.name,
                              })),
                            ],
                            "label"
                          )}
                        />
                      </Col>
                      <Col md={3} xs={12}>
                        <Select
                          isMulti
                          label="Contract"
                          placeholder="Contract"
                          value={this.state.filters.contract}
                          onChange={(newVal) =>
                            this.handleChangeFilters(newVal, "contract")
                          }
                          options={orderBy(
                            [
                              ...this.props.contracts.map((contract) => ({
                                value: contract._id,
                                label: contract.name,
                              })),
                            ],
                            "label"
                          )}
                        />
                      </Col>
                      <Col md={3} xs={12}>
                        <Select
                          isMulti
                          label="Divisions"
                          placeholder="Divisions"
                          value={this.state.filters.division}
                          onChange={(newVal) =>
                            this.handleChangeFilters(newVal, "division")
                          }
                          options={orderBy(
                            [
                              ...this.props.divisions.map((division) => ({
                                value: division._id,
                                label: division.description,
                              })),
                            ],
                            "label"
                          )}
                        />
                      </Col>
                      <Col md={12} xs={12}>
                        <DateRangePicker
                          startDate={
                            this.state.filters.start_time
                              ? moment.utc(this.state.filters.start_time)
                              : null
                          }
                          startDateId="startDate"
                          endDate={
                            this.state.filters.end_time
                              ? moment.utc(this.state.filters.end_time)
                              : null
                          }
                          endDateId="endDate"
                          onDatesChange={({ startDate, endDate }) => {
                            this.handleChangeDate(startDate, endDate);
                          }}
                          isOutsideRange={() => false}
                          focusedInput={this.state.focusedInput}
                          onFocusChange={(focusedInput) =>
                            this.setState({ focusedInput })
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="mb-0">
                      <Col md={12}>
                        <button
                          className="btn btn-warning"
                          type="button"
                          onClick={this.resetFilters}
                        >
                          Reset
                        </button>
                      </Col>
                    </Row>
                  </Formsy>
                </Well>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {/* <pre>{JSON.stringify(this.props.invoicesToApprove, null, 2)}</pre> */}
            {this.state.list && (
              <InvoiceApprovalList
                selected={this.state.selected}
                invoices={this.state.invoices}
                count={this.state.count}
                filters={this.state.filters}
                onClickPreview={this.onClickPreview}
                onClearInvoices={this.props.actions.clearInvoices}
                onToggle={this.handleToggle}
                auth={this.props.auth}
                accounts={this.props.accounts}
                contracts={this.props.contracts}
                onPageSizeChange={this.handlePageSizeChange}
                onPageChange={this.handlePageChange}
                onSortedChange={this.handleSortedChange}
                toggleSelectAll={this.toggleSelectAll}
                loading={this.state.loading}
              />
            )}
            <Modal
              id="invoiceDetailsModal"
              title="Invoice Preview"
              body={invoiceDetails}
              invoice={this.props.invoice}
              modal={this.props.modal}
              onClickExport={this.onClickExport}
              onToggle={this.handleToggle}
              utils={this.props.InvoiceUtils}
              close={this.props.actions.hideModal}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

InvoiceApprovalPage.propTypes = {
  auth: PropTypes.object.isRequired,
  actions: PropTypes.object,
  modal: PropTypes.object,
  invoiceToVoid: PropTypes.string,
  codes: PropTypes.array,
  invoicesToApprove: PropTypes.array,
  invoices: PropTypes.array.isRequired,
  invoice: PropTypes.object.isRequired,
  InvoiceUtils: PropTypes.object,
  contracts: PropTypes.array,
  chargetypes: PropTypes.array,
  contract: PropTypes.object,
  accounts: PropTypes.array,
  account: PropTypes.object,
  users: PropTypes.array,
  items: PropTypes.array,
  divisions: PropTypes.array,
};

function mapStatesToProps(state) {
  return {
    state: state.reducers,
    auth: state.reducers.auth,
    modal: state.reducers.modal,
    invoiceToVoid: state.reducers.invoiceToVoid,
    invoicesToApprove: state.reducers.invoicesToApprove,
    codes: state.reducers.codes,
    invoices: state.reducers.invoices,
    invoice: state.reducers.invoice,
    contracts: state.reducers.contracts,
    divisions: state.reducers.divisions,
    accounts: state.reducers.accounts,
    chargetypes: state.reducers.chargetypes,
    users: state.reducers.users,
    items: state.reducers.items,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...userActions,

        ...divisionActions,
        ...contractActions,
        ...chargetypeActions,
        ...accountActions,
        ...invoiceActions,
        ...userActions,
        ...itemActions,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(InvoiceApprovalPage);
